<template>
    <v-app-bar
      app
      color="primary"
      dark
    >
      <v-container fluid>
        <v-row>
          <v-col class="d-flex justify-start">
            <div class="d-flex align-center clickable" @click="toHome">
              <v-img
                alt="UGO Logo"
                class="shrink mr-2"
                contain
                src="@/assets/logo.svg"
                width="40"
              />

              <span class="text-h4">
                  UGO
              </span>
            </div>
          </v-col>
          <v-col class="d-flex justify-center">
            <ClockDisplay
              :clockObject="clockObject"
            />
          </v-col>
          <v-col class="d-flex justify-end">
        
            <v-menu
              v-model="menu"
              :close-on-content-click="false"
              :nudge-width="200"
              offset-y
              bottom
            >
            <template v-slot:activator="{ on, attrs }">
          
              <v-btn
                text
                v-bind="attrs"
                v-on="on"
              >
                <span class="mr-2">{{username}}</span>
                <v-icon>mdi-account-circle</v-icon>
              </v-btn>
            </template>

            <v-card>
              <v-list>
                <v-list-item>
                  <v-list-item-avatar>
                    <v-icon large>
                      mdi-account-circle
                    </v-icon>
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title>{{username}}</v-list-item-title>
                    <v-list-item-subtitle v-if="company && company.companyName">{{company.companyName}}</v-list-item-subtitle>
                  </v-list-item-content>

                </v-list-item>
              </v-list>

              <v-divider></v-divider>
              <v-list-item-group
                v-model="selectedItem"
                color="primary"
              >
                <v-list-group
                  prepend-icon="mdi-cog"
                  no-action
                  v-if="hasAnalystPrivileges"
                >
                  <template v-slot:activator>
                    <v-list-item-content>
                      <v-list-item-title>Amministrazione</v-list-item-title>
                    </v-list-item-content>
                  </template>
        
                  <v-list-item
                    link
                    :to="{name: 'UsersAnagraphic'}"
                    @click="menu = false"
                    v-if="hasAdminPrivileges"
                  >
                    <v-list-item-icon>
                      <v-icon>mdi-archive</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Anagrafiche</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    link
                    :to="{name: 'DataConsolidation'}"
                    @click="menu = false"
                  >
                    <v-list-item-icon>
                      <v-icon>mdi-folder-lock</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Consolidamento</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    link
                    :to="{name: 'VariationProposals'}"
                    @click="menu = false"
                  >
                    <v-list-item-icon>
                      <v-icon>mdi-check-decagram</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Proposte di Modifica</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                      link
                      :to="{name: 'AbsencesManagement'}"
                      @click="menu = false"
                    >
                      <v-list-item-icon>
                        <v-icon>mdi-clipboard-edit-outline</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Gestione Assenze</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                      link
                      :to="{name: 'UsersDailyStatus'}"
                      @click="menu = false"
                    >
                      <v-list-item-icon>
                        <v-icon>mdi-account-supervisor</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Status Giornaliero</v-list-item-title>
                  </v-list-item>
                  </v-list-group>
                  
                  <v-list-item 
                    :to="{name: 'Reports'}"
                    @click="menu = false"
                    v-if="hasAnalystPrivileges"
                  >
                    <v-list-item-icon>
                      <v-icon>mdi-chart-bar</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>Reportistica</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              <v-divider></v-divider>

              <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn
                  color="red"
                  text
                  @click="requestLogout"
                >
                  <v-icon left>
                    mdi-logout
                  </v-icon>
                  Disconnetti
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-menu>
          </v-col>
        </v-row>
      </v-container>
    </v-app-bar>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import { DateTime } from 'luxon'

export const ClockDisplay = () => import('../global/ClockDisplay.vue')

import loginApi from '../../api/login'
import * as currentUserActions from '../../store/actions/currentUser'

export default {
    data: function() {
      return {
        menu: false,
        selectedItem: null,
      }
    },
    props: {
      clockObject: {
        type: Object,
        required: true
      },
    },
    components: {
      ClockDisplay
    },
    methods: {
      requestLogout() {
        loginApi.logout().then(response => {
          if(response) {
            this.$router.push({name: 'Login'});
            this.logOut();
          }
        })
      },
      toHome() {
        if(this.$route.name != 'Home')
          this.$router.push({name: 'Home'});
      },
      ...mapActions({
        //logIn: currentUserActions.USER_DOLOGIN,
        logOut: currentUserActions.USER_DOLOGOUT,
      })
    },
    computed: {
      ...mapState({
        username: state => state.currentUser.username,
        company: state => state.currentUser.company,
        role: state => state.currentUser.role,
        userRoles: state => state.dataStorage.userRoles
      }),
      timeDisplay: function() {
        return this.clockObject.toLocaleString(DateTime.TIME_24_WITH_SECONDS)
      },
      userRoleObject: function() {
        return this.userRoles[this.role]
      },
      hasAdminPrivileges: function() {
        return this.userRoleObject.value >= this.userRoles['ADMIN'].value;
      },
      hasAnalystPrivileges: function() {
        return this.userRoleObject.value >= this.userRoles['ANALYST'].value;
      }
    },
}
</script>
<style lang="css" scoped>
  .clickable :hover {
    cursor: pointer;
  }
</style>